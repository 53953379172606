export const contact = {
    all: {
        discord_community: {
            icon: 'b:discord',
            url: 'https://discord.gg/nvrA4Gcxve',
            headline: 'Community Discord',
        },
        email_community: {
            icon: 'envelope',
            url: 'mailto:support@pronouns.page',
            headline: 'support@pronouns.page',
        },
        email_technical: {
            icon: 'envelope',
            url: 'mailto:technical@pronouns.page',
            headline: 'technical@pronouns.page',
        },
        email_localisation: {
            icon: 'envelope',
            url: 'mailto:localisation@pronouns.page',
            headline: 'localisation@pronouns.page',
        },
        email: {
            icon: 'envelope',
            url: 'mailto:contact@pronouns.page',
            headline: 'contact@pronouns.page',
        },
    },
    polish: {
        email: {
            icon: 'envelope',
            url: 'mailto:kontakt@zaimki.pl',
            headline: 'kontakt@zaimki.pl',
        },
        email_science: {
            icon: 'envelope',
            url: 'mailto:nauka@zaimki.pl',
            headline: 'nauka@zaimki.pl',
        },
    },
};

export const socialLinks = {
    all: {
        mastodon: {
            icon: 'mastodon',
            iconSet: 'b',
            url: 'https://tech.lgbt/@PronounsPage',
            headline: '@PronounsPage@tech.lgbt',
        },
        bluesky: {
            icon: 'bluesky.svg',
            iconSet: '',
            url: 'https://bsky.app/profile/pronouns.page',
            headline: 'pronouns.page',
        },
        // twitter: {
        //     icon: 'twitter',
        //     iconSet: 'b',
        //     url: 'https://twitter.com/PronounsPage',
        //     headline: '@PronounsPage',
        // },
    },

    polish: {
        mastodon: {
            icon: 'mastodon',
            iconSet: 'b',
            url: 'https://kolektiva.social/@neutratywy',
            headline: '@neutratywy@kolektiva.social',
        },
        bluesky: {
            icon: 'bluesky.svg',
            iconSet: '',
            url: 'https://bsky.app/profile/zaimki.pl',
            headline: 'zaimki.pl',
        },
        // twitter: {
        //     icon: 'twitter',
        //     iconSet: 'b',
        //     url: 'https://twitter.com/neutratywy',
        //     headline: '@neutratywy',
        // },
        instagram: {
            icon: 'instagram',
            iconSet: 'b',
            url: 'https://instagram.com/neutratywy',
            headline: '@neutratywy',
        },
        facebook: {
            icon: 'facebook',
            iconSet: 'b',
            url: 'https://facebook.com/neutratywy',
            headline: 'fb.com/neutratywy',
        },
        tiktok: {
            icon: 'tiktok',
            iconSet: 'b',
            url: 'https://tiktok.com/@neutratywy',
            headline: 'tiktok.com/@neutratywy',
        },
    },

    calendar: {
        mastodon: {
            icon: 'mastodon',
            iconSet: 'b',
            url: 'https://tech.lgbt/@QueerCalendar',
            headline: '@QueerCalendar@tech.lgbt',
        },
        bluesky: {
            icon: 'bluesky.svg',
            iconSet: '',
            url: 'https://bsky.app/profile/calendar.pronouns.page',
            headline: 'calendar.pronouns.page',
        },
        // twitter: {
        //     icon: 'twitter',
        //     iconSet: 'b',
        //     url: 'https://twitter.com/CalendarQueer',
        //     headline: '@CalendarQueer',
        // },
    },

    shop: {
        shop: {
            icon: 'shopping-bag',
            url: 'https://shop.pronouns.page',
            headline: 'shop.pronouns.page',
        },
    },
};

export const COLOURS = {
    mastodon: '#3188d4',
    facebook: '#1877F2',
    linkedin: '#2867B2',
    messenger: '#0099FF',
    odnoklassniki: '#EE8208',
    pinterest: '#e60023',
    pocket: '#EF4154',
    reddit: '#ff4500',
    telegram: '#179CDE',
    twitter: '#1da1f2',
    viber: '#7360f2',
    vkontakte: '#4680C2',
    whatsapp: '#25D366',

    instagram: '#d52a76',
    email: '#18b089',
    faq: '#C71585',
    workshops: '#C71585',
};

const AVATARS = {
    all: 'pronounspage.png',
    polish: 'neutratywy.png',
    calendar: 'calendarqueer.png',
};

const supportLinks = (translator) => {
    return {
        all: {
            bank: {
                icon: 'money-check-alt',
                url: 'https://bunq.me/PronounsPage',
                headline: translator.translate('support.bankAccount'),
                // tooltip: translator.translate('support.bankAccountOwner'),
            },
            kofi: {
                icon: 'coffee',
                url: 'https://ko-fi.com/radajezykaneutralnego',
                headline: 'Ko-Fi',
            },
            paypal: {
                icon: 'paypal',
                iconSet: 'b',
                url: 'https://paypal.me/RJNeutralnego',
                headline: 'PayPal',
            },
        },
    };
};

function* getLink(links, group) {
    const featureLinks = links[group] || {};
    for (const key in featureLinks) {
        if (!featureLinks.hasOwnProperty(key)) {
            continue;
        }
        let avatar = AVATARS[group];
        if (featureLinks[key].url.startsWith('mailto:')) {
            avatar = null;
        }
        yield {
            ...featureLinks[key],
            group,
            avatar,
            colour: COLOURS[key.split('_')[0]] || '#cc3aa3',
        };
    }
}

export function* getContactLinks(config, translator) {
    if (config.faq.enabled) {
        yield {
            url: `/${config.faq.route}`,
            icon: 'map-marker-question',
            headline: translator.translate('faq.headerLong'),
            group: 'all',
            colour: COLOURS.faq,
        };
    }
    if (config.workshops?.enabled) {
        yield {
            url: `/${config.workshops.route}`,
            icon: 'chalkboard-teacher',
            headline: translator.translate('workshops.headerLong'),
            group: 'all',
            colour: COLOURS.workshops,
        };
    }
    yield * getLink(contact, 'all');
    if (config.locale === 'pl') {
        yield * getLink(contact, 'polish');
    }
}

export function* getSocialLinks(config) {
    yield * getLink(socialLinks, 'all');
    if (config.locale === 'pl') {
        yield * getLink(socialLinks, 'polish');
    }
    if (config.calendar && config.calendar.enabled) {
        yield * getLink(socialLinks, 'calendar');
    }
    yield * getLink(socialLinks, 'shop');
}

export function* getSupportLinks(translator) {
    yield * getLink(supportLinks(translator), 'all');
}
